import { Theme } from '@mui/material/styles';
import { CheckboxProps, TableProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function Table(theme: Theme) {
  return {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          position: 'relative',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: theme.palette.action.selected,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          },
          '& .MuiTableCell-body:first-of-type': {
            paddingLeft: '32px',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: TableProps }) => ({
          borderBottom: '1px solid #E7E9EC',
          fontSize: 12,
          padding: ownerState.size === 'medium' ? '20px 0' : '4px 5px',
        }),
        head: {
          '&:first-of-type': {
            '&:not(.checkbox)': {
              paddingLeft: '32px',
            },
          },
          padding: '12px 0',
          fontSize: '14px',
          fontWeight: 400,
          color: '#888D96',
          borderBottom: 'none',
          backgroundColor: '#F3F5F6',
        },
        stickyHeader: {
          backgroundColor: theme.palette.background.paper,
          backgroundImage: `linear-gradient(to bottom, #F3F5F6 0%, #F3F5F6 100%)`,
        },
        paddingCheckbox: {
          paddingLeft: theme.spacing(1),
        },
      },
    },
    MuiTablePagination: {
      defaultProps: {
        backIconButtonProps: {
          size: 'small',
        },
        nextIconButtonProps: {
          size: 'small',
        },
        SelectProps: {
          MenuProps: {
            MenuListProps: {
              sx: {
                '& .MuiMenuItem-root': {
                  ...theme.typography.body2,
                },
              },
            },
          },
        },
      },

      styleOverrides: {
        root: {
          borderTop: `solid 1px ${theme.palette.divider}`,
        },
        toolbar: {
          height: 64,
        },
        actions: {
          marginRight: theme.spacing(1),
        },
        select: {
          '&:focus': {
            borderRadius: theme.shape.borderRadius,
          },
        },
      },
    },
  };
}
