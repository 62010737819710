import {
  AUTH_SHOP_ID_URL,
  AZURE_LOGIN_URL,
  AZURE_TOKEN_URL,
  BOARD_BASE_API_URL,
  BOARD_COMMENTS_API_URL,
  BOARD_FILTER_URL,
  BOARD_LIKES_API_URL,
  BOOK_PERIOD_URL,
  CHANGE_PASSWORD_URL,
  CHANGE_PROFILE_URL,
  CREATE_BOARD_URL,
  DELETE_PASSPORT_URL,
  FILE_DOWNLOAD_URL,
  FILE_UPLOAD_URL,
  PASSPORT_BASE_URL,
  FIND_USER_BASE_URL,
  GET_AUTH_SHOP_INFO_URL,
  GET_CERTIFY_CODE_URL,
  I_READ_BOARD_URL,
  IMPORTANT_BOARD_URL,
  JARDIN_GET_RESERVATION_URL,
  JARDIN_IMAGE_URL,
  JIRA_ID_URL,
  JIRA_ISSUE_URL,
  LINK_LIST_URL,
  LOGIN_BY_AZURE_URL,
  LOGIN_BY_ISSUE_PORTAL_TOKEN_URL,
  MENU_URL,
  MESSAGE_ALL_READ_URL,
  MESSAGE_READ_URL,
  MY_NOTIFICATION_OPTION_LIST_URL,
  MYINFO_URL,
  NEW_BOOK_LIST_URL,
  NEW_EMPLOYEE_INTERVIEWS_URL,
  NOTIFICATION_HISTORY_URL,
  PATCH_PASSPORT_URL,
  PORTAL_PASSPORT_URL,
  PROOF_EMP_HISTORY_URL,
  SEARCH_API_URL,
  SEARCH_EMP_HISTORY_URL,
  SEARCH_PASSPORT_URL,
  SEARCH_POINT_URL,
  SEND_EMAIL_URL,
  SET_TOKEN_URL,
  SIGNATURE_URL,
  UPDATE_COMMENT_LIKE_URL,
  UPDATE_LIKES_URL,
  UPDATE_NICKNAME_URL,
  VERIFY_CERTIFY_CODE_URL,
  MY_NOTIFICATION_OPTION_URL,
  MY_RESERVATION_BOOK_URL,
  CHANGE_LANGUAGE_URL,
  UNREAD_EMAIL_URL,
  BASE_CALENDAR_URL,
  BIRTHDAY_API_URL,
  NEW_BOOK_URL,
  CHANGE_JOB_DESCRIPTION_URL,
  JOB_LIST_URL,
  ORGANIZATION_URL,
  LEGACY_APPROVAL_AUTHOR_URL,
  LEGACY_APPROVAL_REFERRER_URL,
  LEGACY_APPROVAL_TEAM_APPROVAL_URL,
  LEGACY_APPROVAL_TEAM_AUTHOR_URL,
  APPROVAL_TYPE_ALL_URL,
  LEGACY_APPROVAL_ME,
  LEGACY_APPROVAL_RECENT_READ,
  LEGACY_APPROVAL_SEARCH_URL,
  APPROVAL_RECOMMEND_FILTER_URL,
  COMBINED_DEPARTMENT_URL,
} from '@/const/apiList';
import useSWR, { SWRConfiguration } from 'swr';
import {
  ApiTokenType,
  Board,
  BoardContent,
  BoardLikes,
  BookData,
  BookPeriod,
  CreateBoardData,
  DownLoadResponse,
  Employee,
  EmpProofHistoryData,
  GetComments,
  JardinMenu,
  JardinReservationData,
  JiraAccountData,
  JiraData,
  LinkFilterList,
  MenuResponse,
  MSLoginUrl,
  MyInfo,
  NewEmployeeInterview,
  Notification,
  NotiHistoryData,
  NotiWithMeta,
  Passport,
  PortalLoginType,
  PortalToken,
  PortalUserResponse,
  ProfileResponse,
  QuickSearch,
  ReadData,
  SearchResponse,
  SendMailData,
  TokenInfo,
  UploadResponse,
  User,
  MyBook,
  UnreadCount,
  DayOffResponse,
  DayOffParams,
  Dday,
  IBirthdayObj,
  NewBook,
  SearchJobList,
  MyJob,
  Organizations,
  PassportSearchParams,
  ApprovalParams,
  Approval,
  ApprovalType,
  RecommendFilterResponse,
  TotalApprovalParams,
  CategoryType,
  BoardListItem,
  ApprovalList,
} from '@/types/api';
import {
  deleteDataFetcher,
  getNestedDataFetcher,
  getNestedDataFetcherWithMeta,
  getNestedMetaDataFetcher,
  getNestedNotificationDataFetcher,
  makeQueryString,
  patchDataFetcher,
  postDataFetcher,
  putDataFetcher,
} from '@/utils/apis';
import { fetchApi } from '@/utils/api/customAxios';
import { NickName } from '@/components/Profile/SecondItem/MyInfo';
import { Password } from '@/components/Profile/SecondItem/PassWord';
import process from 'process';
import { Elaw, Futube, Meta } from '@/types/legacyApi';
import { E_LAW_INFO, FUTUBE_INFO } from '@/const/legacyApiList';
import useSWRInfinite from 'swr/infinite';
import { getCookie } from '@/utils/tokens';
import { JWT_ACCESS_TOKEN } from '@/const/tokens';
import { msInstance } from '@/utils/api/msAzure/instance';
import { FnCoCompanyCode } from '@/utils/const';
import { useMemo } from 'react';
import { BoardDetail } from '@/types/board';
import { apiURL } from '@/constant/apiURL';
import { formatyyyyMMddString } from '@/utils/date';

const swrConfig: SWRConfiguration = {
  onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
    // Never retry on 404.
    if (error?.response?.status === 404) return;
    if (error?.response?.status === 400) return;
    // Only retry up to 10 times.
    if (retryCount >= 3) return;
    // Retry after 5 seconds.
    setTimeout(() => revalidate({ retryCount }), 5000);
  },
};

// export const useMyInfo = () =>
//   useSWR<MyInfo>(MYINFO_URL, (key) =>
//     fetchApi<{ data: MyInfo }>({
//       url: key,
//       instance: apixInstance,
//       method: 'GET',
//     }).then((res) => res.data),
//   );

export const useMyInfo = () => {
  return fetchApi<{ data: MyInfo }>({
    method: 'GET',
    url: MYINFO_URL,
  }).then((res) => res.data);
};

export const useJardinImage = () =>
  useSWR<JardinMenu>(
    JARDIN_IMAGE_URL,
    getNestedDataFetcher<JardinMenu>,
    swrConfig,
  );

export type BoardSearchParams = {
  listSize: number;
  currentPage: number;
  sortType: string;
  orderType: 'desc' | 'acs';
  boardNos: string;
  documentNo: string;
  category: string;
  dDay: string;
};

export type BoardParams = Pick<BoardSearchParams, 'documentNo' | 'category'> & {
  boardNo: string;
};

export const useBoard = (obj: BoardParams, shouldFetch = true) => {
  const params = new URLSearchParams(obj);
  return useSWR<BoardContent>(
    shouldFetch ? `${BOARD_BASE_API_URL}?${params.toString()}` : null,
    getNestedDataFetcher<BoardContent>,
    {
      shouldRetryOnError: false,
    },
  );
};

export const useBoardLikes = (
  obj: Pick<BoardParams, 'boardNo' | 'documentNo'>,
) => {
  const params = new URLSearchParams(obj);
  return useSWR<BoardLikes>(
    `${BOARD_BASE_API_URL}${BOARD_LIKES_API_URL}?${params.toString()}`,
    getNestedDataFetcher<BoardLikes>,
    swrConfig,
  );
};

export const useNewEmployees = (
  obj: Partial<BoardSearchParams> = {},
  fallback: NewEmployeeInterview[] = [],
) => {
  const newEntries = Object.entries(obj).map(([k, v]) => [
    k,
    typeof v === 'number' ? v.toString() : v,
  ]);
  const newObject = Object.fromEntries(newEntries) as Record<string, string>;

  const params = new URLSearchParams(newObject);
  return useSWR<NewEmployeeInterview[]>(
    `${NEW_EMPLOYEE_INTERVIEWS_URL}?${params.toString()}`,
    getNestedDataFetcher<NewEmployeeInterview[]>,
    { fallbackData: fallback },
  );
};

export const useFindUser = (userId: string, shouldFetch = true) => {
  return useSWR<User>(
    shouldFetch ? `${FIND_USER_BASE_URL}${userId}` : null,
    getNestedDataFetcher<User>,
    swrConfig,
  );
};

export const useUpdateLikes = (
  obj: Pick<BoardParams, 'boardNo' | 'documentNo'>,
) => {
  return postDataFetcher(UPDATE_LIKES_URL, obj);
};

export const useComments = (
  obj: Pick<BoardParams, 'boardNo' | 'documentNo'>,
) => {
  const params = new URLSearchParams(obj);
  return useSWR<GetComments>(
    `${BOARD_BASE_API_URL}${BOARD_COMMENTS_API_URL}?${params.toString()}`,
    getNestedDataFetcher<GetComments>,
    swrConfig,
  );
};

export const useUpdateComment = (
  obj: Pick<BoardParams, 'boardNo' | 'documentNo'>,
) => {
  return postDataFetcher(UPDATE_LIKES_URL, obj);
};

export const useUpdateCommentLike = (obj: { snsKey: number }) => {
  return postDataFetcher(UPDATE_COMMENT_LIKE_URL, obj);
};

const REDIRECT_URL = `${process.env.NEXT_PUBLIC_ORIGIN_URL}/redirect`;

export const useAzureLoginUrl = () => {
  return useSWR<MSLoginUrl>(
    `${AZURE_LOGIN_URL}?redirectUri=${REDIRECT_URL}`,
    getNestedDataFetcher<MSLoginUrl>,
    swrConfig,
  );
};

export const useAzureChangeLoginUrl = () => {
  return useSWR<MSLoginUrl>(
    `${AZURE_LOGIN_URL}?prompt=select_account&redirectUri=${REDIRECT_URL}`,
    getNestedDataFetcher<MSLoginUrl>,
    swrConfig,
  );
};

export type MSLoginResponse = {
  token_type: string;
  access_token: string;
  expires_at: number;
  refresh_token: string;
};

export const useAzureToken = (code?: string) => {
  return useSWR<MSLoginResponse>(
    code ? `${AZURE_TOKEN_URL}?code=${code}&redirectUri=${REDIRECT_URL}` : null,
    getNestedDataFetcher<MSLoginResponse>,
    swrConfig,
  );
};

export const useLoginByAzure = (accessToken?: string) => {
  return fetchApi<{ data: TokenInfo }>({
    method: 'POST',
    url: LOGIN_BY_AZURE_URL,
    data: {
      accessToken: accessToken,
    },
  });
};

export const usePortalLogin = (params: PortalLoginType) => {
  return postDataFetcher<PortalLoginType, PortalToken>(
    LOGIN_BY_ISSUE_PORTAL_TOKEN_URL,
    params,
  );
};

const makeParamstring = (params: Record<string, unknown> = {}) => {
  const paramToString = Object.entries(params)
    .map(([k, v]) => `${k}=${v}`)
    .join('&');
  const newParamString = paramToString === '' ? `?${paramToString}` : '';
  return newParamString;
};

type LinkCategory = 'link' | 'club' | 'office365' | 'powerBi' | 'guide';

export const useLinkList = (category: LinkCategory) => {
  return useSWR<LinkFilterList>(
    `${LINK_LIST_URL}?category=${category}&service=portal&sortType=sortNo&displayed=1`,
    getNestedDataFetcher<LinkFilterList>,
    swrConfig,
  );
};

export const useSearchEmployee = (search: string) => {
  return useSWR<Employee[]>(
    () =>
      search === '' || search.length < 2
        ? null
        : `${SEARCH_API_URL}?searchString=${search}`,
    getNestedDataFetcher<Employee[]>,
    swrConfig,
  );
};

export const useEmpSearch = (search: string) => {
  return useSWR<SearchResponse>(
    () =>
      search.length < 2
        ? null
        : `${SEARCH_PASSPORT_URL}?listSize=1000&keyword=${search}`,
    getNestedMetaDataFetcher<SearchResponse>,
    swrConfig,
  );
};

type Param = {
  search: string;
  listSize: number;
};

export const useEmpSearch2 = ({ search, listSize }: Param) => {
  return useSWR<SearchResponse>(
    () =>
      search.length < 2
        ? null
        : `${SEARCH_PASSPORT_URL}?listSize=${listSize}&keyword=${search}`,
    getNestedMetaDataFetcher<SearchResponse>,
    swrConfig,
  );
};

export const useFindPassport = (passportId: string, shouldFetch = true) => {
  return useSWR<Passport>(
    shouldFetch ? `${PASSPORT_BASE_URL}/${passportId}` : null,
    getNestedDataFetcher<Passport>,
    swrConfig,
  );
};

export type BoardFilterParams = BoardSearchParams & {
  mainBannerType: 'Y' | 'R' | 'N';
  title: string;
  userId: string;
  userName: string;
  companyCode: string;
  displayed: number;
};

export const useFilterBoard = (
  obj: Pick<
    BoardFilterParams,
    'listSize' | 'currentPage' | 'mainBannerType' | 'companyCode'
  >,
  fallback?: Board[],
) => {
  const queryParams = `${BOARD_FILTER_URL}?listSize=${obj.listSize}&currentPage=${obj.currentPage}&mainBannerType=${obj.mainBannerType}`;
  const companyCodeQueryParam = FnCoCompanyCode.includes(obj.companyCode)
    ? `&companyCode=${obj.companyCode}`
    : '';

  return useSWR<Board[]>(
    queryParams + companyCodeQueryParam,
    getNestedDataFetcher<Board[]>,
    { fallbackData: fallback },
  );
};

export const useImportantBoard = () => {
  return useSWR<Board[]>(IMPORTANT_BOARD_URL, getNestedDataFetcher);
};

export const useMyBook = () => {
  return useSWR<MyBook[]>(MY_RESERVATION_BOOK_URL, getNestedDataFetcher);
};

export const useSearchHistory = () => {
  return useSWR<QuickSearch[]>(
    SEARCH_EMP_HISTORY_URL,
    getNestedDataFetcher<QuickSearch[]>,
    swrConfig,
  );
};

export const useDeleteSearchHistory = () => {
  return deleteDataFetcher(DELETE_PASSPORT_URL);
};

export const useDeleteSearchEmployee = (passportId: string) => {
  return deleteDataFetcher(`${DELETE_PASSPORT_URL}/${passportId}`);
};

export type SaveSearchParams = {
  name: string;
  userId: string;
  positionNameKor: string;
  organizationShortName: string;
  profileImageUrl: string;
};

export const useSaveSearchData = (obj: QuickSearch) => {
  return postDataFetcher(SEARCH_EMP_HISTORY_URL, obj);
};

export const useUpdateNickName = (obj: NickName) => {
  return patchDataFetcher(UPDATE_NICKNAME_URL, obj);
};

export const useSearchPoint = (userNo: string) => {
  return useSWR<{ employeePoint: number }>(
    `${SEARCH_POINT_URL}/${userNo}`,
    getNestedDataFetcher,
    swrConfig,
  );
};

export type SignatureData = {
  companyAddress: string;
  companyLogoImageUrl: string;
  displayOrganizationShortName: string;
  email: string;
  homePageUrl: string;
  insidePhone: string;
  mobilePhone: string;
  name: string;
  nickName: string;
};
export const useSignatureData = (userNo: string) => {
  return useSWR<SignatureData>(
    `${SIGNATURE_URL}/${userNo}`,
    getNestedDataFetcher,
    swrConfig,
  );
};

export const useChangePassWord = (obj: Password) => {
  return postDataFetcher(CHANGE_PASSWORD_URL, obj);
};

type CertifyCodeOption = {
  telephoneNumber: string;
  serviceName: string;
};
export const useGetCertifyCode = (mobileNumber: string) => {
  return postDataFetcher<
    CertifyCodeOption,
    { data: { smsVerificationId: string } }
  >(GET_CERTIFY_CODE_URL, {
    telephoneNumber: mobileNumber,
    serviceName: 'employmentVerification',
  });
};

export type CertifyForm = {
  smsVerificationId: string;
  smsVerificationCode: string;
};

export const useCertifySmsCode = (obj: CertifyForm) => {
  return postDataFetcher(`${VERIFY_CERTIFY_CODE_URL}`, obj);
};

export type PrintParams = {
  passportId: string;
  outputReason: string;
  smsVerificationId: string;
  smsVerificationCode: string;
};

export const usePrintEmpProof = (obj: PrintParams) => {
  return fetchApi<{ data: string }>({
    method: 'GET',
    url: `/api/v1/passport/${obj.passportId}/certificate-employment/pdf?outputReason=${obj.outputReason}&smsVerificationId=${obj.smsVerificationId}&smsVerificationCode=${obj.smsVerificationCode}`,
  }).then((res) => res.data);
};

export type EmpProofHistoryParams = {
  employeeNumber: string;
  listSize: number;
  currentPage: number;
};

export const useHistoryEmpProof = (obj: EmpProofHistoryParams) => {
  return useSWR<EmpProofHistoryData[]>(
    `${PROOF_EMP_HISTORY_URL}?employeeNumber=${obj.employeeNumber}&listSize=${obj.listSize}&currentPage=${obj.currentPage}`,
    getNestedDataFetcher,
    swrConfig,
  );
};

export type AuthShopId = {
  onlineMallId: string;
  onlineMallPassword: string;
  employeeNumber: string;
};

export const useAuthShopId = (obj: AuthShopId) => {
  return postDataFetcher(AUTH_SHOP_ID_URL, obj);
};

export const useShopAuthInfo = (employeeNumber: string) => {
  return useSWR<{ customerId: string }>(
    `${GET_AUTH_SHOP_INFO_URL}/${employeeNumber}`,
    getNestedDataFetcher,
    swrConfig,
  );
};

export const useCreateBoard = () => {
  return useSWR<CreateBoardData[]>(
    CREATE_BOARD_URL,
    getNestedDataFetcher,
    swrConfig,
  );
};

export const useSendMail = (obj: SendMailData) => {
  return postDataFetcher(SEND_EMAIL_URL, obj);
};

export const useNewBooks = () => {
  return useSWR<BookData[]>(NEW_BOOK_LIST_URL, getNestedDataFetcher, swrConfig);
};

export const useBookPeriod = () => {
  return useSWR<BookPeriod>(BOOK_PERIOD_URL, getNestedDataFetcher, swrConfig);
};

export const useReadBoard = (obj: ReadData) => {
  return postDataFetcher(I_READ_BOARD_URL, obj);
};

export const useJiraId = () => {
  return useSWR<JiraAccountData>(JIRA_ID_URL, getNestedDataFetcher, swrConfig);
};

export const useJiraData = (obj?: JiraAccountData) => {
  const queryParam = new URLSearchParams(obj);
  return useSWR<JiraData[]>(
    obj ? `${JIRA_ISSUE_URL}?${queryParam}` : null,
    getNestedDataFetcher,
    swrConfig,
  );
};

export const useNotiHistory = () => {
  return useSWR<NotiWithMeta>(
    `${NOTIFICATION_HISTORY_URL}?listSize=50`,
    (url) =>
      fetchApi<NotiWithMeta>({
        method: 'GET',
        url,
        baseURL: process.env.NEXT_PUBLIC_NOTIFICATION_URL,
      }).then((res) => res),
  );
};

const getNotificationKey = (page: number) =>
  `${NOTIFICATION_HISTORY_URL}?listSize=10&currentPage=${page + 1}`;
export const useNotiHistoryPagination = () => {
  return useSWRInfinite<NotiHistoryData[]>(
    getNotificationKey,
    getNestedNotificationDataFetcher<NotiHistoryData[]>,
  );
};

export const useElawInfo = () => useSWR<Elaw>(E_LAW_INFO, getNestedDataFetcher);

export const useFutubeInfo = () =>
  useSWR<Futube>(FUTUBE_INFO, getNestedDataFetcher);

export const useJardinReservation = (email: string) => {
  return useSWR<JardinReservationData>(
    email ? JARDIN_GET_RESERVATION_URL : null,
    (url) =>
      fetchApi<{ response: JardinReservationData }>({
        method: 'POST',
        apiTokenType: ApiTokenType.APIX,
        url,
        baseURL: process.env.NEXT_PUBLIC_APIX_API_URL,
        data: {
          email: email,
        },
      }).then((res) => res.response),
  );
};

export const useSetToken = (token: string) => {
  return fetchApi({
    method: 'POST',
    url: SET_TOKEN_URL,
    baseURL: process.env.NEXT_PUBLIC_NOTIFICATION_URL,
    data: {
      token: token,
      os: 'web',
      agent: navigator.userAgent,
      tokenKind: 'firebase',
      serviceName: 'portal',
    },
  }).catch((e) => {
    if (e?.response?.status === 400) return;
    return e;
  });
};

export const useProfile = ({ id, obj }: { id: string; obj: File }) => {
  return fetchApi<{ data: ProfileResponse }>({
    extraHeaders: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'POST',
    url: `${PASSPORT_BASE_URL}/${id}/profile-image`,
    data: {
      file: obj,
    },
  });
};

export const useMessageAllRead = () => {
  return fetchApi({
    method: 'PATCH',
    url: MESSAGE_ALL_READ_URL,
    baseURL: process.env.NEXT_PUBLIC_NOTIFICATION_URL,
  });
};

export const useMessageRead = (id: string) => {
  return fetchApi({
    method: 'PATCH',
    url: `${MESSAGE_READ_URL}/${id}`,
    baseURL: process.env.NEXT_PUBLIC_NOTIFICATION_URL,
  });
};

export const useMenu = (myInfo?: MyInfo) => {
  return useSWR<MenuResponse>(
    myInfo?.companyCode
      ? `${MENU_URL}?type=global_navigation_bar&service=portal&companyCode=${myInfo.companyCode}`
      : null,
    getNestedDataFetcher,
    swrConfig,
  );
};

export const useMobileMenu = (myInfo?: MyInfo) => {
  return useSWR<MenuResponse>(
    myInfo?.companyCode
      ? `${MENU_URL}?type=global_navigation_bar&service=portal_mobile&companyCode=${myInfo.companyCode}`
      : null,
    getNestedDataFetcher,
    swrConfig,
  );
};

export const usePassport = () => {
  return useSWR<PortalUserResponse>(
    PORTAL_PASSPORT_URL,
    (key) =>
      fetchApi<{ data: PortalUserResponse }>({
        method: 'GET',
        url: key,
        extraHeaders: {
          Authorization: `Bearer ${getCookie(JWT_ACCESS_TOKEN)}`,
        },
      }).then((res) => res.data),
    swrConfig,
  );
};

export const useChangePassport = (passportId: string) => {
  return fetchApi({
    method: 'PATCH',
    url: PATCH_PASSPORT_URL,
    data: {
      passportId: passportId,
    },
    extraHeaders: {
      Authorization: `Bearer ${getCookie(JWT_ACCESS_TOKEN)}`,
    },
  });
};

export const useChangeLanguage = (language: string) => {
  return patchDataFetcher(CHANGE_LANGUAGE_URL, { lang: language });
};

export const useUploadFile = (obj?: File) => {
  return fetchApi<{ data: UploadResponse }>({
    extraHeaders: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'POST',
    url: FILE_UPLOAD_URL,
    data: {
      downloadType: 'public',
      file: obj,
    },
  });
};

export const useGetFileLink = (uuid: string) => {
  return fetchApi<{ data: DownLoadResponse }>({
    method: 'GET',
    url: `${FILE_DOWNLOAD_URL}?uuid=${uuid}`,
  });
};

export const useMyNotiList = () => {
  return useSWR<Notification[]>(
    MY_NOTIFICATION_OPTION_LIST_URL,
    getNestedNotificationDataFetcher<Notification[]>,
  );
};

export const useMyNotiOptionChange = ({
  templateKey,
  activated,
}: Pick<Notification, 'templateKey' | 'activated'>) => {
  return fetchApi({
    method: 'POST',
    url: MY_NOTIFICATION_OPTION_URL,
    baseURL: process.env.NEXT_PUBLIC_NOTIFICATION_URL,
    data: {
      templateKey,
      activated: activated ? 1 : 0,
    },
  });
};

export const useMsUnreadCount = () => {
  return useSWR<UnreadCount>(UNREAD_EMAIL_URL, (url) =>
    fetchApi<UnreadCount>({
      method: 'GET',
      instance: msInstance,
      url: url,
      apiTokenType: ApiTokenType.MS,
    }).then((res) => res),
  );
};

export const usePassportInfo = (jwtAccessToken: string) => {
  return fetchApi<{ data: PortalUserResponse }>({
    method: 'GET',
    url: PORTAL_PASSPORT_URL,
    extraHeaders: {
      Authorization: `Bearer ${jwtAccessToken}`,
    },
  });
};

export const useSendMessage = (id: string, obj: { message: string }) =>
  postDataFetcher(`${PASSPORT_BASE_URL}/${id}/happy-birthday/email`, obj);

export const useGetDayOff = (passportId: string, obj: DayOffParams) =>
  useSWR<DayOffResponse>(`${PASSPORT_BASE_URL}/${passportId}/day-off`, (url) =>
    fetchApi<{ data: DayOffResponse }>({
      method: 'GET',
      url: url,
      data: obj,
    }).then((res) => res.data),
  );

export const useGetDday = () =>
  useSWR<Dday[]>(`${BASE_CALENDAR_URL}/event/d-day`, getNestedDataFetcher);

/* Social API */
const now = new Date();
const todayString = formatyyyyMMddString(now, '-');
export const useSocialCategorys = () =>
  useSWR<CategoryType[]>(
    `/api/v1/board/category/list/filter?listSize=2000`,
    getNestedDataFetcher,
  );
export const useSocialSearch = (searchText = '') => {
  const params = {
    subject: searchText,
  };
  return useSWR<BoardListItem[]>(
    `/api/v1/board${makeQueryString(params, true)}`,
    getNestedDataFetcher,
  );
};

export const useSearchSocialBoardWithFilter = (
  searchText = '',
  categoryId = '',
  groupId = '',
  isBookmark = false,
  isRead = false,
) => {
  const { data, size, setSize, isValidating, mutate, isLoading } =
    useSWRInfinite<any>(
      (index, previousPageData) => {
        const params = {
          subject: searchText,
          categoryId,
          categoryGroupId: groupId,
          isBookmark,
          isRead,
          listSize: 18,
          displayEndedAt: todayString,
          sortType: 'displayStartedAt',
          orderType: 'desc',
          deleted: false,
          // displaySections: 'bannerSection', //todo 테스트용
          currentPage: index + 1,
        };
        return `/api/v1/board${makeQueryString(params, true, true)}`;
      },
      getNestedDataFetcherWithMeta,
      { revalidateFirstPage: false, revalidateOnFocus: false },
    );

  const { content, totalPage } = useMemo(() => {
    if (data) {
      const flatData = [...data.flatMap((page) => page)];
      return {
        content: [...flatData.map((page) => page.content)],
        totalPage: flatData[0].meta.endPage,
      };
    }
    return { content: [], totalPage: null };
  }, [data]);

  const loadNext = () => setSize((prev) => prev + 1);
  const hasNext = useMemo(() => {
    if (!totalPage) return false;
    return size < totalPage;
  }, [size, totalPage]);
  const isRefreshing = isValidating && data && data.length < size;

  return {
    data: data ? content : [],
    mutate,
    loadNext,
    hasNext,
    isValidating,
    isLoading,
    isRefreshing,
  };
};

/* 게시글 조회 */
export const useGetBoardDetail = (documentId: string) => {
  return useSWR<BoardDetail>(
    documentId ? apiURL.BOARD_DETAIL(documentId) : null,
    getNestedDataFetcher,
  );
};
export const useDeleteBoard = async (
  documentId: string,
): Promise<boolean | string> => {
  if (!documentId) return '잘못된 요청입니다. 새로고침 후 다시 시도해 주세요.';
  try {
    // TODO 삭제 성공 시 값 확인 후 처리
    const response = await deleteDataFetcher(apiURL.BOARD_DETAIL(documentId));
    return !!response;
  } catch (e: any) {
    return (
      e.response?.data?.message ||
      '게시글을 삭제 실패. 새로고침 후 다시 시도해 주세요.'
    );
  }
};

export const useGetBirthdays = () =>
  useSWR<IBirthdayObj>(BIRTHDAY_API_URL, getNestedDataFetcher<IBirthdayObj>);

export const useGetNewBookCount = () =>
  useSWR<NewBook>(NEW_BOOK_URL, getNestedDataFetcher);

export const useChangedJob = (arg: MyJob) =>
  putDataFetcher(CHANGE_JOB_DESCRIPTION_URL, arg);

export const useGetJobList = (search: string) =>
  useSWR<SearchJobList>(
    search
      ? `${JOB_LIST_URL}?listSize=500&currentPage=1&keyword=${search}`
      : `${JOB_LIST_URL}?listSize=500&currentPage=1`,
    getNestedMetaDataFetcher,
  );

export const useGetOrganization = () =>
  useSWR<Organizations>(ORGANIZATION_URL, getNestedMetaDataFetcher);

export const useSearchPassport = (obj?: PassportSearchParams) => {
  const queryParam = new URLSearchParams(obj);

  return useSWR<{ meta: Meta; data: Passport[] }>(
    obj ? `${SEARCH_PASSPORT_URL}?${queryParam}` : null,
    getNestedMetaDataFetcher,
  );
};

export const useGetApprovalData = (obj: ApprovalParams) => {
  const newEntries = Object.entries(obj).map(([k, v]) => [
    k,
    typeof v === 'number' ? v.toString() : v,
  ]);
  const newObject = Object.fromEntries(newEntries) as Record<string, string>;

  const queryParam = new URLSearchParams(newObject);

  return useSWR<{ meta: Meta; data: Approval[] }>(
    `${LEGACY_APPROVAL_AUTHOR_URL}?${queryParam}`,
    getNestedMetaDataFetcher,
  );
};

export const useGetMyReferrerApprovalData = (
  obj: ApprovalParams,
  config?: SWRConfiguration,
) => {
  const newEntries = Object.entries(obj).map(([k, v]) => [
    k,
    typeof v === 'number' ? v.toString() : v,
  ]);
  const newObject = Object.fromEntries(newEntries) as Record<string, string>;

  const queryParam = new URLSearchParams(newObject);

  return useSWR<{ meta: Meta; data: Approval[] }>(
    `${LEGACY_APPROVAL_REFERRER_URL}?${queryParam}`,
    getNestedMetaDataFetcher,
    config,
  );
};

export const useGetMyTeamWriteApprovalData = (obj: ApprovalParams) => {
  const newEntries = Object.entries(obj).map(([k, v]) => [
    k,
    typeof v === 'number' ? v.toString() : v,
  ]);
  const newObject = Object.fromEntries(newEntries) as Record<string, string>;

  const queryParam = new URLSearchParams(newObject);

  return useSWR<{ meta: Meta; data: Approval[] }>(
    `${LEGACY_APPROVAL_TEAM_AUTHOR_URL}?${queryParam}`,
    getNestedMetaDataFetcher,
  );
};

export const useGetMyTeamApprovalData = (obj: ApprovalParams) => {
  const newEntries = Object.entries(obj).map(([k, v]) => [
    k,
    typeof v === 'number' ? v.toString() : v,
  ]);
  const newObject = Object.fromEntries(newEntries) as Record<string, string>;

  const queryParam = new URLSearchParams(newObject);

  return useSWR<{ meta: Meta; data: Approval[] }>(
    `${LEGACY_APPROVAL_TEAM_APPROVAL_URL}?${queryParam}`,
    getNestedMetaDataFetcher,
  );
};

export const useGetApprovalType = () =>
  useSWR<ApprovalType[]>(APPROVAL_TYPE_ALL_URL, getNestedDataFetcher);

export const useGetMyApprovalData = (
  obj: ApprovalParams,
  config?: SWRConfiguration,
) => {
  const newEntries = Object.entries(obj).map(([k, v]) => [
    k,
    typeof v === 'number' ? v.toString() : v,
  ]);
  const newObject = Object.fromEntries(newEntries) as Record<string, string>;

  const queryParam = new URLSearchParams(newObject);

  return useSWR<{ meta: Meta; data: Approval[] }>(
    `${LEGACY_APPROVAL_ME}?${queryParam}`,
    getNestedMetaDataFetcher,
    config,
  );
};

export const useRecentReadApproval = () =>
  useSWR<Approval[]>(LEGACY_APPROVAL_RECENT_READ, getNestedDataFetcher);

export const useSearchApproval = (obj: TotalApprovalParams) => {
  const filteredEntries = Object.entries(obj).filter(
    ([_, v]) =>
      v !== undefined && v !== '' && (Array.isArray(v) ? v.length > 0 : true),
  );

  const newEntries = filteredEntries.map(([k, v]) => [
    k,
    typeof v === 'number' ? v.toString() : v,
  ]);
  const newObject = Object.fromEntries(newEntries) as Record<string, string>;

  const queryParam = new URLSearchParams(newObject);

  return useSWR<ApprovalList>(
    `${LEGACY_APPROVAL_SEARCH_URL}?${queryParam}`,
    getNestedMetaDataFetcher,
  );
};

export const useGetFilterRecommend = () =>
  useSWR<RecommendFilterResponse>(
    APPROVAL_RECOMMEND_FILTER_URL,
    getNestedDataFetcher,
  );

export const useGetCombinedDepartment = (code: string) => {
  return useSWR<string[]>(
    code ? `${COMBINED_DEPARTMENT_URL}/${code}` : null,
    getNestedDataFetcher,
  );
};
