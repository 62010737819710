import { alpha, Theme } from '@mui/material/styles';
import { ButtonProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function Button(theme: Theme) {
  const rootStyle = (ownerState: ButtonProps) => {
    const inheritColor = ownerState.color === 'inherit';
    const containedVariant = ownerState.variant === 'contained';
    const outlinedVariant = ownerState.variant === 'outlined';
    const textVariant = ownerState.variant === 'text';
    const smallSize = ownerState.size === 'small';
    const largeSize = ownerState.size === 'large';

    const defaultStyle = {
      fontWeight: 400,
      ...(inheritColor && {
        // CONTAINED
        ...(containedVariant && {
          color: theme.palette.grey[800],
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: theme.palette.grey[400],
          },
        }),
        // OUTLINED
        ...(outlinedVariant && {
          color: '#000',
          borderColor: '#E7E9EC',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.01)',
          },
        }),
        // TEXT
        ...(textVariant && {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        }),
      }),
    };

    const disabledState = {
      '&.Mui-disabled': {
        //
      },
    };

    const size = {
      ...(smallSize && {
        height: 30,
        fontSize: 13,
        padding: '4px 10px',
      }),
      ...(largeSize && {
        height: 48,
        fontSize: 16,
        padding: '12px 16px',
        borderRadius: '6px',
      }),
    };

    return [defaultStyle, disabledState, size];
  };

  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },

      styleOverrides: {
        root: ({ ownerState }: { ownerState: ButtonProps }) =>
          rootStyle(ownerState),
      },
    },
  };
}
