import { Theme } from '@mui/material/styles';
import { SelectProps } from '@mui/material';
import { SelectArrow } from '@/theme/overrides/CustomIcons';
import React from 'react';

// ----------------------------------------------------------------------

export default function Select(theme: Theme) {
  return {
    MuiSelect: {
      defaultProps: {
        IconComponent: (_props: any) => {
          const rotate = _props.className.toString().includes('iconOpen');
          const disabled = _props.className.toString().includes('Mui-disabled');
          return (
            <SelectArrow
              style={{
                position: 'absolute',
                right: 16,
                height: '20px',
                width: '20px',
                transform: rotate ? 'rotate(180deg)' : 'none',
              }}
              stroke={disabled ? '#D1D8E2' : 'black'}
            />
          );
        },
      },

      styleOverrides: {
        root: ({ ownerState }: { ownerState: SelectProps }) => ({
          paddingRight: 40,
          '&.Mui-disabled': {
            background: '#F9FAFB',
            borderColor: '#E7E9EC',
            color: '#D1D8E2',
          },
        }),
      },
    },
  };
}
