import { Meta } from '@/types/legacyApi';

export type HomepageProps = {
  myInfo?: MyInfo;
  birthday?: IBirthdayObj;
  banner?: Board[];
  interview?: NewEmployeeInterview[];
};

export enum ApiTokenType {
  APIX = 'APIX',
  MS = 'MS',
  JWT = 'JWT',
}

export type JardinMenu = {
  createAt: string;
  type: string;
  updateAt: string;
  imageUrl: string;
};

export type JardinClosedTimeResponseDetail = {
  reserved_date: string;
  reserved_time: string;
  closed_yn: 'N' | 'Y';
  type: 'here' | 'togo';
};

export type JardinClosedTimeResponse = {
  [date: string]: {
    [time: string]: 'N' | 'Y';
  };
};

export type JardinClosedTime = {
  message: string;
  response: JardinClosedTimeResponse;
  status: boolean;
};

export type BoardContent = {
  boardNo: string;
  documentNo: number;
  userName: string;
  deptName: string;
  title: string;
  createdDateTime: string;
  updatedDateTime: string;
  createdDate: string;
  updatedDate: string;
  content: string;
  readCount: number;
  commentCount: number;
  recommendCount: number;
  isMainBanner: number;
  imageUrls: string[];
  viewImage?: 'T' | 'B' | 'L' | 'S';
};

export type Board = {
  boardNo: string;
  documentNo: number;
  category: string;
  boardName: string;
  userName: string;
  deptName: string;
  title: string;
  createdDateTime: string;
  updatedDateTime: string;
  createdDate: string;
  updatedDate: string;
  readCount: number;
  commentCount: number;
  recommendCount: number;
  isMainBanner: number;
  dDay: string;
  thumbnailUrl: string;
  thumbnailPosition: string;
  contentPreview: string;
  watched: 0 | 1;
};

export type NewEmployeeInterview = {
  boardNo: string;
  documentNo: number;
  category: string;
  userName: string;
  deptName: string;
  title: string;
  createdDateTime: string;
  updatedDateTime: string;
  createdDate: string;
  updatedDate: string;
  content: string;
  readCount: number;
  commentCount: number;
  recommendCount: number;
  isMainBanner: number;
  watched: 0 | 1;
};

export type BoardLike = {
  boardNo: string;
  documentNo: number;
  userId: string;
  userName: string;
  readDateTime: string;
  readDate: string;
};

export type BoardLikes = {
  totalCount: number;
  details: BoardLike[];
};

export type User = {
  userId: string;
  email: string;
  name: string;
  nickName: string;
  placeName: string;
  deptName: string;
  positionName: string;
  mobilePhone: string;
  insidePhone: string;
  enterDate: string;
  companyName: string;
  userImageUrl: string;
  employeeNumber: string;
  organizationDisplayFullName: string;
  organizationDisplayShortName: string;
  birthDate: string;
  organizationDisplaySimpleName: string;
};

export type MyInfo = PassportDetail & {
  userId: string;
  passportId: string;
  enterDate: string;
} & { language?: string };

export type ApprovalState = {
  headerKey: number;
  myApprovalKey: number;
  myWriteKey: number;
  unRead: boolean;
};

export type WebApprovalState = {
  open: boolean;
  keyword: string;
};

export type ApprovalSortType = 'docId' | 'authoredAt' | 'updatedAt';

export type ApprovalTempFilter = {
  types: ApprovalType[];
  authorPassportIds: FilterPassport[];
  approverPassportIds: FilterPassport[];
  currentApproverPassportIds: FilterPassport[];
  coApproverPassportIds: FilterPassport[];
};

export type ApprovalFilter = {
  id: number;
  sortType: ApprovalSortType;
  types: ApprovalType[];
  authorPassportIds: FilterPassport[];
  approverPassportIds: FilterPassport[];
  approverStatuses: string[];
  currentApproverPassportIds: FilterPassport[];
  coApproverPassportIds: FilterPassport[];
  coApproverStatuses: string[];
  statuses: FilterStatus[];
  referrerPassportIds: FilterPassport[];
  authorCombinedDepartments: string[];
  approverCombinedDepartments: string[];
  endAuthoredAt: PeriodStatus[];
  unreadPassportIds: FilterPassport[];
};

export type PeoPleFilterName =
  | 'authorPassportIds'
  | 'approverPassportIds'
  | 'currentApproverPassportIds'
  | 'coApproverPassportIds';

export type StatusFilterName = 'endAuthoredAt' | 'statuses';

export type FilterPassport = {
  passportId: string;
  profileImageUrl: string;
  positionName: string;
  name: string;
  divisionName: string;
  departmentName: string;
};

export type TotalApprovalParams = {
  types?: string[];
  listSize?: number;
  currentPage?: number;
  sortType?: ApprovalSortType;
  orderType?: 'desc' | 'asc';
  keyword?: string;
  authorPassportIds?: string[];
  approverPassportIds?: string[];
  approverStatuses?: string;
  currentApproverPassportIds?: string[];
  coApproverPassportIds?: string[];
  coApproverStatuses?: string;
  statuses?: string[];
  referrerPassportIds?: string[];
  authorCombinedDepartments?: string[];
  approverCombinedDepartments?: string[];
  startAuthoredAt?: string;
  endAuthoredAt?: string;
  unreadPassportIds?: string[];
};

export type FilterStatus = {
  name: string;
  type: number;
  value: string;
  value2?: string;
};

export type PeriodStatus = {
  name: string;
  type: number;
  value: string;
  value2: string;
};

export type PassportDetail = {
  employeeNumber: string;
  name: string;
  nickname: string;
  email: string;
  positionName: string;
  positionCode: string;
  departmentName: string;
  departmentCode: string;
  divisionName: string;
  divisionCode: string;
  companyCode: string;
  companyName: string;
  organizationFullName: string;
  organizationShortName: string;
  profileImageUrl: string;
  phoneInside: string;
  phoneMobile: string;
  birthdayType: number;
  birthday: string;
};

export type Employee = {
  userId: string;
  email: string;
  employeeNumber: string;
  employeeNameKor: string;
  employeeNameEng: string;
  employeeNameChn: string;
  organizationCode: string;
  organizationName: string;
  organizationDisplayName: string;
  organizationShortName: string;
  nickName: string;
  phoneMobile: string;
  phoneInside: string;
  joinDate: string;
  birthDate: string;
  birthDateType: string;
  companyCode: string;
  companyName: string;
  placeCode: string;
  placeName: string;
  departmentCode: string;
  departmentName: string;
  positionCode: string;
  positionNameKor: string;
  positionNameEng: string;
  profileImageUrl: string;
};

export type FnFComment = {
  boardNo: string;
  documentNo: number;
  commendNo: number;
  snsKey: number;
  recommendCount: number;
  recommended: 0 | 1;
  userId: string;
  userName: string;
  userNickName: string;
  userProfileUrl: string;
  deptName: string;
  content: string;
  deleted: number;
  createdDate: string;
  createdDateTime: string;
};

export type GetComments = {
  totalCount: number;
  details: FnFComment[];
};

export type MSLoginUrl = {
  msAzureLoginUrl: string;
};

export type LinkFilter = {
  id: number;
  service: string;
  category: string;
  displayed: number;
  uri: string;
  name: string;
  description: string;
  icon: string;
  sortNo: number;
  createdUserId: string;
  updatedUserId: string;
  createdAt: string;
  updatedAt: string;
};

export type LinkFilterList = Partial<LinkFilter>[];

export type MsAzureToken = {
  id: string;
  mail: string;
  userId: string;
  displayName: string;
  givenName: string;
  jobTitle: string;
  mobilePhone: string;
  officeLocation: string;
  preferredLanguage: string;
  surname: string;
  userPrincipalName: string;
};

export type ApixToken = {
  tokenType: string;
  key: string;
  expiresAt: string;
};

export type JwtToken = {
  tokenType: string;
  accessToken: string;
  expiresAt: string;
  refreshToken: string;
  refreshExpiresAt: string;
};

export type JwtRefresh = {
  data: {
    token_type: string;
    access_token: string;
    expires_at: number;
  };
};

export type TokenInfo = {
  msAzure: MsAzureToken;
  apix: ApixToken;
  jwt: JwtToken;
};

export type AzureRefreshToken = {
  data: {
    token_type: string;
    access_token: string;
    expires_at: number;
    refresh_token: string;
  };
};

export type UnreadCount = {
  data: { total: number };
};

export type IssueTokenParams = {
  grant_type: string;
  loginId: string;
  password: string;
  provider: string;
  access_token: string;
  id_token: string;
  refresh_token: string;
};
export type PortalIssueTokenParamsByPasswordToken = Pick<
  IssueTokenParams,
  'grant_type' | 'loginId' | 'password'
>;

export type PortalIssueTokenParamsByAuthToken = Pick<
  IssueTokenParams,
  'grant_type' | 'provider' | 'access_token'
>;

export type PortalIssueTokenParamsByRefreshToken = Pick<
  IssueTokenParams,
  'grant_type' | 'refresh_token'
>;

export type PortalLoginType =
  | PortalIssueTokenParamsByPasswordToken
  | PortalIssueTokenParamsByAuthToken
  | PortalIssueTokenParamsByRefreshToken;

export type PortalToken = {
  data: {
    token_type: string;
    access_token: string;
    refresh_token: string;
    expires_in: number;
    refresh_expires_in: number;
  };
};

export type EmpProofHistoryData = {
  cartNumber: number;
  businessDeptName: string;
  businessBumoonName: string;
  workStartDate: string;
  workEndDate: string;
  ouputReason: string;
  createDate: string;
  status: string;
};

export type CreateBoardData = {
  boardName: string;
  boardNo: string;
  category: string;
  subBoards?: CreateBoardData[];
};

export type SendMailData = {
  senderName: string;
  senderEmail: string;
  templateKey: string;
  receiverEmail: string;
  templateData: { name: string; message: string };
};

export type BookData = {
  bookId: string;
  title: string;
  isbn: string;
  author: string;
  recommendCount: number;
  readCount: number;
  rentCount: number;
  ceoRecommend: string;
  imageUrl: string;
  createdAt: string;
};

export type MyBook = BookData & {
  rentalStartDate: string;
  rentalEndDate: string;
  rentalReturnDate: string;
};

export type ReadData = {
  boardNo: string;
  documentNo: string;
};

export type BookPeriod = {
  title?: string;
  startDate?: string;
  endDate?: string;
  createdAt?: string;
  updatedAt?: string;
};

export type JiraAccountData = {
  jiraAccountId: string;
};

export type JiraData = {
  summary: string;
  statusName: string;
  issue: {
    id: string;
    key: string;
    url: string;
  };
  assignee: {
    avatarUrls: string;
    displayName: string;
    emailAddress: string;
    accountId: string;
  };
};

export type CreateCommentData = {
  boardNo: string;
  documentNo: string;
  content: string;
  isAnonymous: number;
};

export type NotiHistoryData = {
  id: number;
  data: {
    mobileActionType: string;
    tabName: string;
  };
  title: string;
  body: string;
  link: string;
  senderId: string;
  templateKey: string;
  createdAt: string;
  watched: 0 | 1;
};

export type NotiWithMeta = {
  data: NotiHistoryData[];
  meta: { endPage: number };
};

export type JardinReservation = {
  created_at: string;
  dept: string;
  email: string;
  empno: string;
  order_no: number;
  phone: string;
  qty: number;
  requests?: string;
  reserved_date: string;
  reserved_time: string;
  //종료|대기|노쇼|거절|확정 후 취소|확정 후 취소 패널티|확정 후 취소 종료|확정 후 취소 확인|확정 전 취소|확정
  status_code:
    | 'C'
    | 'N'
    | 'NS'
    | 'XA'
    | 'XCA'
    | 'XCAP'
    | 'XCAPX'
    | 'XCAX'
    | 'XCB'
    | 'Y';
  status_name: string;
  type: 'here' | 'togo';
  update_at: null;
  username: string;
};

export type JardinReservationData = {
  [date: string]: {
    [time: string]: JardinReservation[];
  };
};

export type ProfileResponse = {
  profileUrl: string;
};

export type MenuData = {
  name: string;
  link?: string;
  action?: 'layer' | 'modal' | 'newTab';
  gridNumber?: number;
  isDisplay: boolean;
  childData: MenuData[];
};

export type MenuResponse = {
  companyName: string;
  data: MenuData[];
};
export type QuickSearch = {
  passportId: string;
  name: string;
  positionName: string;
  organizationShortName: string;
  departmentName: string;
  divisionName: string;
  profileImageUrl: string;
};

export type SearchResponse = {
  meta: Meta;
  data: Passport[];
};

export type Passport = {
  passportId: string;
  detail: PassportDetail;
  jobDescription: JobDescription;
  job: string;
};

export type BirthdayPassPort = {
  passport: Passport;
  isSendingEmail: boolean;
};

export interface IBirthdayObj {
  [day: string]: BirthdayPassPort[];
}

export type Language = 'ko' | 'en' | 'zh' | 'default';
export type LanguageValue<T> = {
  [key in Language]: T;
};

export type PortalUserResponse = {
  id: string;
  loginId: string;
  personalization: {
    lang: Language;
  };
  defaultPassport: Passport;
  assignedPassports: Passport[];
};

export type UploadResponse = {
  uuid: string;
  fileName: string;
  fileHashName: string;
  downloadExpirationSeconds: number;
  fileSize: string;
  filePassword: string;
  createdAt: string;
};

export type DownLoadResponse = {
  url: string;
  type: string;
  expiredAt: string;
};

export type Notification = {
  templateKey: string;
  serviceName: string;
  activated: boolean;
};

export type DayOffParams = {
  year: string;
};

export type DayOffResponse = {
  totalMonthlyLeaveDays: number;
  totalAnnualLeaveDays: number;
  overages: number;
  possibleLeaveDays: number;
  annualLeaves: DayOff[];
  usedDays: number;
  remainingDays: number;
  unusedMonthlyLeaveDays: number;
};

export type DayOff = {
  startDate: string;
  endDate: string;
  days: number;
  documentId: string;
};

export type Dday = {
  id: string;
  calendarId: string;
  summary: string;
  description: string;
  start: Day;
  end: Day;
  meta: {
    dDay: boolean;
    location: string;
    link: string;
  };
};

export type Day = {
  allDay: boolean;
  datetime: string;
  timezone: string;
};

export type NewBook = {
  newBookCount: number;
  books: BookData[];
};

export type JobDescription = {
  summary: string;
  content: string;
  updatedAt: string;
};

export type Job = {
  id: number;
  name_kor: string;
  name_eng: string;
  name_chn: string;
  name_jpn: string;
  createdAt: string;
  updatedAt: string;
};

export type SearchJobList = {
  meta: Meta;
  data: Job[];
};

export type MyJob = {
  job: string;
  summary: string;
  content: string;
};

export type Organizations = {
  meta: Meta;
  data: Organization[];
};

export type Organization = {
  orgCode: string;
  parentOrgCode: string;
  parentName: string;
  nameKor: string;
  nameEng: string;
  nameChn: string;
  nameJpn: string;
  nameShort: string;
  description: string;
  totalPassportCount: number;
  children: Organization[];
};

export type PassportSearchParams = {
  listSize?: string;
  currentPage?: string;
  sortType?: string;
  orderType?: string;
  orgCode?: string;
  keyword?: string;
};

export type SortType = 'divisionName' | 'name' | 'positionCode' | 'email' | '';

export type ApprovalParams = {
  listSize?: number;
  currentPage?: number;
  sortType?: 'authoredAt';
  orderType?: 'desc' | 'asc';
  statuses?: string;
  isUnread?: boolean;
  approverType?: 1 | 2;
  myTurn?: boolean;
  keyword?: string;
};

export type Approval = {
  docId: string;
  type: {
    type: number;
    name: string;
    tag: string;
  };
  status: string;
  subject: string;
  author: {
    passportId: string;
    name: string;
    employeeNumber: string;
    companyName: string;
    companyCode: string;
    placeName: string;
    placeCode: string;
    departmentName: string;
    departmentCode: string;
    positionName: string;
    positionCode: string;
    profileImageUrl: string;
  };
  currentApprover: {
    passportId: string;
    type: number;
    order: number;
    name: string;
    placeCode: string;
    placeName: string;
    departmentCode: string;
    departmentName: string;
    positionCode: string;
    positionName: string;
    opinion: null;
    approvedAt: string;
    createdAt: string;
    updatedAt: string;
  };
  finalApprover: {
    passportId: string;
    type: number;
    order: number;
    name: string;
    placeCode: string;
    placeName: string;
    departmentCode: string;
    departmentName: string;
    positionCode: string;
    positionName: string;
    opinion: null;
    approvedAt: null;
    createdAt: string;
    updatedAt: string;
  };
  opinionCount: number;
  commentCount?: number;
  authoredAt: string;
  latestApprovedAt: string;
  createdAt: string;
  updatedAt: string;
  link: string;
};

export type ApprovalType = {
  type: number;
  name: string;
  tag: string;
};

export type RecommendFilterResponse = {
  types: number[];
  authorPassports: Passport[];
  approverPassports: Passport[];
  currentApproverPassports: Passport[];
  coApproverPassports: Passport[];
};

export type ApprovalList = {
  meta: Meta;
  data: Approval[];
};

export type CategoryType = {
  id: string;
  name: string;
  discription: string;
  groupId: string | null;
  group: string | null;
  groupSortNumber: number;
};

export type SocialBoardContent = {
  id: string;
  subject: string;
  content: string;
  createdAt: string;
  thumbnails: Thumbnail[];
  displaySections: string[];
  commentCount: number;
  likeCount: number;
  recommendCount: number;
  isRead: boolean;
  isLike: boolean;
  isBookmark: boolean;
  isRecommend: boolean;
  isImportant: boolean;
  categories: string[];
  displayStartedAt: string;
  displayEndedAt: string | null;
  homeDisplayStartedAt: string;
  homeDisplayEndedAt: string | null;
  createdBy: {
    name: string;
    nickname: string;
    departmentName: string;
    positionName: string;
    divisionName: string;
  };
};

export type Thumbnail = {
  position: string;
  link: string;
};

export type BoardCreatedBy = {
  departmentName: string;
  divisionName: string;
  name: string;
  nickName: string | null;
  positionName: string;
};

export type BoardListItem = {
  categories: string[];
  commentCount: number;
  content: string;
  createdAt: string;
  createdBy: BoardCreatedBy;
  subject: string;
  id: string;
};
