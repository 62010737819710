import { ReactNode } from 'react';
import { lightTheme, darkTheme } from '@/styles/theme';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { useColorModeContext } from '@/contexts/ColorModeContext';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { Pretendard } from '@/styles/fonts';
import ComponentsOverrides from '@/theme/overrides';
import palette from '@/theme/palette';

const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const { colorMode } = useColorModeContext();

  const theme = createTheme({
    palette: palette(colorMode === 'light' ? 'light' : 'dark'),
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1440,
      },
    },
    typography: {
      fontFamily: Pretendard.style.fontFamily,
      button: {
        textTransform: 'none',
        fontFamily: Pretendard.style.fontFamily,
      },
      h1: { fontSize: '40px' },
      h2: { fontSize: '36px' },
      h3: { fontSize: '32px' },
      h4: { fontSize: '28px' },
      h5: { fontSize: '24px' },
      h6: { fontSize: '20px' },
      subtitle1: { fontSize: '18px', fontWeight: 500 },
      subtitle2: { fontSize: '16px', fontWeight: 500 },
      body1: { fontSize: '16px', fontWeight: 400 },
      body2: { fontSize: '14px', fontWeight: 400 },
    },
    components: {
      MuiSkeleton: {
        defaultProps: {
          animation: 'wave',
        },
      },
    },
  });

  theme.components = ComponentsOverrides(theme);

  return (
    <MuiThemeProvider theme={theme}>
      <EmotionThemeProvider
        theme={colorMode === 'light' ? lightTheme : darkTheme}
        // theme={lightTheme}
      >
        {children}
      </EmotionThemeProvider>
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
