import type { AppContext, AppProps } from 'next/app';
import { SWRConfig } from 'swr';
import ThemeProvider from '@/components/ThemeProvider';
import GlobalStyles from '@/styles/GlobalStyles';
import { ColorModeProvider } from '@/contexts/ColorModeContext';
import InlineScript from '@/styles/InlineScript';
import Layout from '@/layout/Layout';
import '@/public/fonts/Font.css';
import { RecoilRoot } from 'recoil';
import { INIT_MY_INFO } from '@/const/initData';
import { MyInfoState } from '@/recoil/atom';
import SeoHeader from '@/components/SeoHeader';
import '@fortawesome/fontawesome-svg-core/styles.css';
import LoadingLottie from '@/components/LoadingLottie';
import { useEffect, useState } from 'react';
import useHotjar from 'react-use-hotjar';
import process from 'process';
import { useRouter } from 'next/router';
import { I18nextProvider } from 'react-i18next';
import i18n from '../public/utils/i18n';
import { CustomDialogProvider } from '@/components/CustomDialog/CustomDialogProvider';
import { AuthProvider } from '@/auth/AuthContext';

const myCustomLogger = console.log;

const App = ({ Component, pageProps }: AppProps<AppContext>) => {
  const [loading, setLoading] = useState(true);
  const [opacity, setOpacity] = useState<0 | 1>(1);
  const router = useRouter();
  const needLoading =
    router.route === '/' || router.route === '/m' || router.route === '/login';

  const { initHotjar } = useHotjar();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2300); // 로딩 애니메이션이 최소 1회는 전부 출력되도록 하기 위한 딜레이
    setTimeout(() => {
      setOpacity(0);
    }, 1800); // 로딩 애니메이션이 자연스럽게 사라지도록 하기 위한 딜레이
  }, []);

  useEffect(() => {
    initHotjar(
      process.env.NEXT_PUBLIC_ORIGIN_URL === 'https://portal.fnf.co.kr'
        ? 3730425
        : 3854712,
      6,
      false,
      myCustomLogger,
    );
  }, [initHotjar]);

  return (
    <>
      <SeoHeader />
      <AuthProvider>
        <SWRConfig>
          <ColorModeProvider>
            <InlineScript />
            <ThemeProvider>
              <GlobalStyles />
              <I18nextProvider i18n={i18n}>
                <RecoilRoot
                  initializeState={({ set }) => set(MyInfoState, INIT_MY_INFO)}
                >
                  <CustomDialogProvider>
                    {needLoading && loading && (
                      <LoadingLottie opacity={opacity} />
                    )}
                    <Layout>
                      <Component {...pageProps} />
                    </Layout>
                  </CustomDialogProvider>
                </RecoilRoot>
              </I18nextProvider>
            </ThemeProvider>
          </ColorModeProvider>
        </SWRConfig>
      </AuthProvider>
    </>
  );
};

export default App;
