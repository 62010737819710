import { SvgIcon, SvgIconProps } from '@mui/material';

// ----------------------------------------------------------------------

// Using for Checkbox
export function CheckboxIcon(props: SvgIconProps) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 21" fill="none" {...props}>
      <rect x="0.5" y="0.517578" width="19" height="19" rx="1.5" fill="white" />
      <rect
        x="0.5"
        y="0.517578"
        width="19"
        height="19"
        rx="1.5"
        stroke="#B7BBC2"
      />
    </svg>
  );
}

export function CheckboxCheckedIcon(props: SvgIconProps) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 21" fill="none" {...props}>
      <rect y="0.0175781" width="20" height="20" rx="2" fill="black" />
      <path
        d="M14.9746 6.24023L8.57821 13.6275L4.97461 10.0239"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function GrayArrowDown(props: SvgIconProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <g clipPath="url(#clip0_1478_48734)">
        <path
          d="M7 10L12 15L17 10"
          stroke="#34383D"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1478_48734">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function GrayArrowUp(props: SvgIconProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <g clipPath="url(#clip0_1478_22366)">
        <path
          d="M7 14.5L12 9.5L17 14.5"
          stroke="black"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1478_22366">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function GrayDoubleArrowLeft(props: SvgIconProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 17" fill="none" {...props}>
      <path
        d="M7 14.7598L0.999999 7.75977L7 0.759766"
        stroke="#5F636B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 14.7598L8 7.75977L14 0.759766"
        stroke="#5F636B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function GrayDoubleArrowRight(props: SvgIconProps) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 17" fill="none" {...props}>
      <path
        d="M9 15.5098L15 8.50977L9 1.50977"
        stroke="#5F636B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 15.5098L7 8.50977L1 1.50977"
        stroke="#5F636B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function EditIcon(props: SvgIconProps) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M9.86133 15.4877L15.083 15.4877"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M15.178 7.05914L7.74315 14.494L4.55798 14.9006L4.97392 11.7248L12.4088 4.2899L15.178 7.05914Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function CircleDeleteIcon(props: SvgIconProps) {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" {...props}>
      <circle cx="8.60352" cy="8.41406" r="8" fill="#D4D7DB" />
      <path
        d="M11.0029 6.01367L6.203 10.8137"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.20312 6.01367L11.0031 10.8137"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SearchIcon(props: SvgIconProps) {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" {...props}>
      <path
        d="M16.25 16.6641L13.2339 13.648M13.2339 13.648C14.2393 12.6427 14.8611 11.2538 14.8611 9.71965C14.8611 6.65139 12.3738 4.16406 9.30556 4.16406C6.23731 4.16406 3.75 6.65139 3.75 9.71965C3.75 12.7879 6.23731 15.2752 9.30556 15.2752C10.8397 15.2752 12.2286 14.6534 13.2339 13.648Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SelectArrow(props: SvgIconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      stroke="black"
      fill="none"
      {...props}
    >
      <g id="20 / Arrow01">
        <path
          id="Vector 266"
          d="M5.83301 8.33301L9.99967 12.4997L14.1663 8.33301"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export function WhiteClose(props: SvgIconProps) {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" {...props}>
      <g id="shape">
        <path
          id="Vector 46"
          d="M8.75 1.25L1.25011 8.75"
          stroke="white"
          strokeWidth="0.857143"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector 47"
          d="M1.25 1.25L8.74989 8.75"
          stroke="white"
          strokeWidth="0.857143"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export function BookmarkDefault(props: SvgIconProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M6.25332 5.09491C6.25332 4.874 6.43241 4.69491 6.65332 4.69491H17.3465C17.5674 4.69491 17.7465 4.874 17.7465 5.09491V18.6968C17.7465 19.0187 17.3856 19.2088 17.1202 19.0266L12.9054 16.1335C12.3598 15.7591 11.64 15.7591 11.0945 16.1335L6.87969 19.0266C6.61426 19.2088 6.25332 19.0187 6.25332 18.6968V5.09491Z"
        stroke="#888D96"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function BookmarkChecked(props: SvgIconProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M5.65332 5.09491C5.65332 4.54263 6.10104 4.09491 6.65332 4.09491H17.3465C17.8988 4.09491 18.3465 4.54262 18.3465 5.09491V18.6968C18.3465 19.5016 17.4442 19.9767 16.7806 19.5212L12.5658 16.6282C12.2249 16.3942 11.775 16.3942 11.434 16.6282L7.21923 19.5212C6.55567 19.9767 5.65332 19.5016 5.65332 18.6968V5.09491Z"
        fill="black"
      />
    </svg>
  );
}

export function VerticalLine(props: SvgIconProps) {
  return (
    <svg width="2" height="16" viewBox="0 0 2 16" fill="none" {...props}>
      <path d="M1 0.5L0.999999 16.5" stroke="#E7E9EC" strokeLinecap="round" />
    </svg>
  );
}

export function DisplayTypeList(props: SvgIconProps) {
  const { stroke, fill, ...option } = props;
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...option}>
      <g clipPath="url(#clip0_3230_128174)">
        <path
          d="M9.94287 7.49921L18.3429 7.49921"
          stroke={stroke || '#B7BBC2'}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="6.24282" cy="7.4" r="1.1" fill={fill || '#B7BBC2'} />
        <path
          d="M9.94287 11.8592L18.3429 11.8592"
          stroke={stroke || '#B7BBC2'}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="6.24282" cy="11.76" r="1.1" fill={fill || '#B7BBC2'} />
        <path
          d="M9.94287 16.2192H18.3429"
          stroke={stroke || '#B7BBC2'}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="6.24282" cy="16.12" r="1.1" fill={fill || '#B7BBC2'} />
      </g>
      <defs>
        <clipPath id="clip0_3230_128174">
          <rect width="24" height="24" rx="3.42857" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function DisplayTypeImage(props: SvgIconProps) {
  const { stroke, fill, ...option } = props;
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...option}>
      <rect
        x="5.5"
        y="5.5"
        width="5"
        height="5"
        stroke={stroke || '#B7BBC2'}
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <rect
        x="13.5"
        y="5.5"
        width="5"
        height="5"
        stroke={stroke || '#B7BBC2'}
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <rect
        x="13.5"
        y="13.5"
        width="5"
        height="5"
        stroke={stroke || '#B7BBC2'}
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <rect
        x="5.5"
        y="13.5"
        width="5"
        height="5"
        stroke={stroke || '#B7BBC2'}
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function EmptyIcon(props: SvgIconProps) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
      <g id="40 / Empty">
        <g id="Group 15632">
          <g id="Group 15633">
            <path
              id="Vector 807"
              d="M20.2935 8.41406V10.9989"
              stroke="#E7E9EC"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector 809"
              d="M14.001 8.91406L15.2934 11.1526"
              stroke="#E7E9EC"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              id="Vector 808"
              d="M26.5859 8.91406L25.2935 11.1526"
              stroke="#E7E9EC"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <path
            id="Vector 806"
            d="M6.80078 22.9842H14.8491C14.8687 23.9829 15.4953 25.9802 17.8452 25.9802C20.195 25.9802 22.3491 25.9802 23.1324 25.9802C23.8765 25.6865 25.3647 24.6761 25.3647 22.9842H33.2956M6.80078 22.9842V31.9137H33.2956V22.9842M6.80078 22.9842L12.4992 17.8145H27.4209L33.2956 22.9842"
            stroke="#E7E9EC"
            strokeWidth="1.7"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
}

export function ErrorEmptyIcon(props: SvgIconProps) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
      <g id="40 / Error">
        <g id="shape">
          <circle
            id="Ellipse 15"
            cx="20"
            cy="20.4141"
            r="13"
            stroke="#E7E9EC"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <rect
            id="Rectangle 86"
            x="20"
            y="27.0811"
            width="0.0162493"
            height="0.0158327"
            stroke="#E7E9EC"
            strokeWidth="2.1"
            strokeLinejoin="round"
          />
          <path
            id="Vector 26"
            d="M20 21.1963L20 14.4163"
            stroke="#E7E9EC"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
}

export function LikeDefaultIcon(props: SvgIconProps) {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" {...props}>
      <path
        d="M13.5367 7.59631L8.35126 12.7818C8.156 12.977 7.83942 12.977 7.64416 12.7818L2.45871 7.59631C1.02679 6.16438 1.02678 3.84278 2.45871 2.41086C3.89063 0.978933 6.21223 0.978933 7.64416 2.41086C7.83942 2.60612 8.156 2.60612 8.35126 2.41086C9.78319 0.978933 12.1048 0.978933 13.5367 2.41086C14.9686 3.84278 14.9686 6.16438 13.5367 7.59631Z"
        stroke="#888D96"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export function LikeFillIcon(props: SvgIconProps) {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" {...props}>
      <path
        d="M13.8903 7.94986L8.70482 13.1353C8.31429 13.5258 7.68113 13.5258 7.2906 13.1353L2.10515 7.94986C0.47797 6.32267 0.477969 3.68449 2.10515 2.0573C3.73234 0.430118 6.37053 0.430118 7.99771 2.0573C9.6249 0.430118 12.2631 0.430118 13.8903 2.0573C15.5175 3.68449 15.5175 6.32267 13.8903 7.94986Z"
        fill="black"
      />
    </svg>
  );
}

export function CommentDefaultIcon(props: SvgIconProps) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M12.0083 9.90195C12.7115 8.68557 12.9478 7.2548 12.6731 5.8769C12.3984 4.49901 11.6315 3.26825 10.5155 2.41455C9.39964 1.56085 8.0111 1.14261 6.60932 1.23795C5.20755 1.33329 3.88843 1.93569 2.89839 2.93263C1.90835 3.92956 1.31511 5.25283 1.2295 6.65523C1.14389 8.05763 1.57176 9.44324 2.43319 10.5532C3.29461 11.6632 4.53066 12.4215 5.91042 12.6867C7.29019 12.9518 8.7193 12.7056 9.93076 11.994L12.7861 12.7908L12.0083 9.90195Z"
        stroke="#888D96"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function CommentFillIcon(props: SvgIconProps) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M13.01 10.4823C13.8538 9.02269 14.1374 7.30576 13.8077 5.65229C13.4781 3.99881 12.5577 2.5219 11.2187 1.49747C9.87956 0.473026 8.21331 -0.0288699 6.53119 0.085539C4.84906 0.199948 3.26612 0.922835 2.07807 2.11916C0.89002 3.31548 0.178135 4.9034 0.0754023 6.58628C-0.0273306 8.26916 0.486116 9.93189 1.51982 11.2638C2.55353 12.5958 4.03679 13.5059 5.69251 13.824C7.34823 14.1422 9.06315 13.8467 10.5169 12.9928L13.9433 13.949L13.01 10.4823Z"
        fill="black"
      />
    </svg>
  );
}
