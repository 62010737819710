export const MS_ACCESS_TOKEN = 'ms_access_token';
export const MS_REFRESH_TOKEN = 'ms_refresh_token';

export const USER_NAME = 'username';
export const APIX_KEY = 'apix_key';
export const APIX_REFRESH_KEY = 'apix_refresh_key';

export const JWT_ACCESS_TOKEN = 'jwt_access_token';
export const JWT_REFRESH_TOKEN = 'jwt_refresh_token';

export const DISPLAY_TYPE = 'display_type';

export const FIREBASE_TOKEN = 'firebase_token';

export const TOKEN_KEY_SET = [
  MS_ACCESS_TOKEN,
  MS_REFRESH_TOKEN,
  USER_NAME,
  APIX_KEY,
  JWT_ACCESS_TOKEN,
  JWT_REFRESH_TOKEN,
];

export const twoWeek = 24 * 60 * 60 * 14;
