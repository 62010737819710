import { Theme } from '@mui/material/styles';
import { DividerProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function Divider(theme: Theme) {
  return {
    MuiDivider: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: DividerProps }) => ({
          borderColor: '#E7E9EC',
        }),
      },
    },
  };
}
