import React, { ReactNode } from 'react';
import { StyledLayout } from '@/layout/styles';
import HeaderV1 from '@/components/Header/HeaderV1';
import { useRouter } from 'next/router';

type P = {
  children: ReactNode;
};
const Layout = ({ children }: P) => {
  const router = useRouter();
  const v2Version =
    router.pathname.startsWith('/organization') ||
    router.pathname.startsWith('/approval') ||
    router.pathname.startsWith('/m');

  if (
    router.pathname.startsWith('/board') ||
    router.pathname.startsWith('/social')
  ) {
    return <>{children}</>;
  } else {
    return (
      <StyledLayout v2Version={v2Version}>
        {!v2Version && <HeaderV1 />}
        {children}
      </StyledLayout>
    );
  }
};

export default Layout;
