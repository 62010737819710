export const MYINFO_URL = '/api/auth/apix/my';

export const JARDIN_IMAGE_URL =
  '/api/v1/jardin/findLatestJardinImageByType/{type}?type=jardin_menu';

export const JARDIN_GET_RESERVATION_URL = '/jardin/get-reservation';

export const BIRTHDAY_API_URL = '/api/v1/passport/birthday';

export const BOARD_BASE_API_URL = '/api/v0/board';
export const BOARD_LIKES_API_URL = '/likes';
export const BOARD_COMMENTS_API_URL = '/comments';

export const NEW_EMPLOYEE_INTERVIEWS_URL = '/api/v0/board/filter/newEmployees';

export const IMPORTANT_BOARD_URL = '/api/v0/board/filter/important';

export const BOARD_BASE_API_URL_V1 = '/api/v1/board';
export const BOARD_FEEDBACK_V1 = `${BOARD_BASE_API_URL_V1}/feedback`;

export const MY_RESERVATION_BOOK_URL = '/api/v0/book/list/my';

export const BOARD_URL = 'https://i.fnf.co.kr/bbs/V';

export const FIND_USER_BASE_URL = '/api/v1/user/findByUserId/';

export const UPDATE_LIKES_URL = `${BOARD_BASE_API_URL}/likes/like`;

export const BOARD_FILTER_URL = `${BOARD_BASE_API_URL}/filter`;

export const UPDATE_COMMENT_LIKE_URL = `${BOARD_BASE_API_URL}${BOARD_COMMENTS_API_URL}/like`;

export const AZURE_LOGIN_URL = `/api/auth/azure/login/url`;

export const AZURE_TOKEN_URL = '/api/auth/azure/token';

export const LOGIN_BY_AZURE_URL = '/api/auth/loginBy/msAzure';
export const LOGIN_BY_ISSUE_PORTAL_TOKEN_URL = '/api/auth/portal/token';

export const LINK_LIST_URL = `/api/v1/link/list/filter`;

export const SEARCH_API_URL = '/api/v2/user/users/search';

export const PASSPORT_BASE_URL = '/api/v1/passport';

export const SEARCH_PASSPORT_URL = `${PASSPORT_BASE_URL}/search`;

export const SEARCH_EMP_HISTORY_URL = '/api/v1/history/quick-search';

export const DELETE_PASSPORT_URL = `${SEARCH_EMP_HISTORY_URL}/data`;

export const SAVE_SEARCH_URL = `${SEARCH_EMP_HISTORY_URL}/data`;

export const DELETE_ALL_SEARCH_URL = `${SAVE_SEARCH_URL}/all`;

export const UPDATE_NICKNAME_URL =
  '/api/v1/portal-user/passport/default/nickname';

export const SEARCH_POINT_URL = '/api/v1/user/findPointByEmployeeNumber';

export const SIGNATURE_URL = 'api/v1/user/getEmailSignature';

export const CHANGE_PASSWORD_URL = '/api/v1/user/updateUserPassword';

export const GET_CERTIFY_CODE_URL = '/api/auth/code/sms/createCodeAndSendBySms';

export const VERIFY_CERTIFY_CODE_URL =
  '/api/auth/code/sms/checkSmsVerificationCode';

export const PRINT_EMP_PROOF_URL =
  '/api/v1/company/hr/getEmploymentVerificationLetter';

export const PROOF_EMP_HISTORY_URL = '/api/v1/company/hr/getHrCart';

export const AUTH_SHOP_ID_URL = '/api/v1/user/authenticateEmployeeOnlineMall';

export const GET_AUTH_SHOP_INFO_URL = '/api/v1/user/getUserCustomerId';

export const CREATE_BOARD_URL = '/api/v0/board/create/creatableBoards';

export const BASE_BOOK_URL = '/api/v0/book';

export const NEW_BOOK_LIST_URL = `${BASE_BOOK_URL}/list/latest`;

export const NEW_BOOK_URL = `${BASE_BOOK_URL}/latest`;

export const BOOK_PERIOD_URL = '/api/v0/book/request/period';

export const SEND_EMAIL_URL = '/api/v1/email/sendByTemplate';

export const UNREAD_EMAIL_URL = '/api/v1/labs/msAzureClient/email/unreadCount';

export const I_READ_BOARD_URL = '/api/v0/board/readers/reader';

export const JIRA_ID_URL = '/api/v1/labs/jiraClient/accountId';

export const JIRA_ISSUE_URL = '/api/v1/labs/jiraClient/issues';

export const CREATE_COMMENT_URL = '/api/v0/board/comments';

export const NOTIFICATION_HISTORY_URL = '/api/v1/notificationCenter/history/my';

export const CHANGE_PROFILE_URL = '/api/v1/user/update/profileImage';

export const SET_TOKEN_URL = '/api/v1/notificationCenter/tokens/my/create';

export const MESSAGE_READ_URL = '/api/v1/notificationCenter/history/watch';

export const MESSAGE_ALL_READ_URL = `${MESSAGE_READ_URL}/my`;

export const MENU_URL = '/api/v1/menu';

export const PORTAL_PASSPORT_URL = '/api/v1/portal-user/me';

export const PATCH_PASSPORT_URL = '/api/v1/portal-user/passport/default';

export const FILE_UPLOAD_URL = '/api/v1/file/upload';

export const FILE_DOWNLOAD_URL = '/api/v1/file/download';

export const MY_NOTIFICATION_OPTION_LIST_URL =
  '/api/v1/notificationCenter/option/my';

export const MY_NOTIFICATION_OPTION_URL =
  '/api/v1/notificationCenter/option/update';

export const CHANGE_LANGUAGE_URL = '/api/v1/portal-user/personalization';

export const BASE_CALENDAR_URL = '/api/v1/calendar';

export const CHANGE_JOB_DESCRIPTION_URL = '/api/v1/passport/me/job';

export const JOB_LIST_URL = '/api/v1/passport/job';

export const ORGANIZATION_URL = '/api/v1/organization/relationship/all';

export const LEGACY_APPROVAL_ME = '/api/v1/legacy-approval/approver/me';

export const LEGACY_APPROVAL_AUTHOR_URL = '/api/v1/legacy-approval/author/me';

export const LEGACY_APPROVAL_REFERRER_URL =
  '/api/v1/legacy-approval/referrer/me';

export const LEGACY_APPROVAL_TEAM_AUTHOR_URL =
  '/api/v1/legacy-approval/author/my-department';

export const LEGACY_APPROVAL_TEAM_APPROVAL_URL =
  '/api/v1/legacy-approval/approver/my-department';

export const APPROVAL_TYPE_ALL_URL = '/api/v1/approval/type/all';

export const LEGACY_APPROVAL_RECENT_READ =
  '/api/v1/history/recently-viewed-approval';

export const LEGACY_APPROVAL_SEARCH_URL = '/api/v1/legacy-approval';

export const APPROVAL_RECOMMEND_FILTER_URL =
  '/api/v1/history/approval-filter/recommend';

export const COMBINED_DEPARTMENT_URL = '/api/v1/legacy-approval-department';
