import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Menu(theme: Theme) {
  return {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          color: '#B7BBC2',
          background: 'inherit',
          borderRadius: '4px',
          padding: 0,
          marginBottom: '12px',
          '&:last-of-type': {
            marginBottom: 0,
          },
          '&:hover': {
            color: 'black',
          },
          '&.Mui-selected': {
            background: 'transparent',
            color: 'black',
            '&:hover': {
              background: theme.palette.action.hover,
            },
            '&:focus': {
              background: theme.palette.action.hover,
            },
          },
          '&.Mui-disabled': {
            background: '#F9FAFB',
            borderColor: '#E7E9EC',
            color: '#D1D8E2',
          },
        },
      },
    },
  };
}
